import { Button, Stack, Text } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import NextLink from 'next/link'
import { Discord } from '../../icons/discord'

const MetaverseButton = () => {
  const { t } = useTranslation('footer')

  return (
    <Button
      as={NextLink}
      bg="transparent"
      variant="edgy"
      _hover={{ backgroundColor: 'transparent', color: 'txtDarkGray' }}
      alignSelf="center"
      href="https://twlvxtwlv.com/r/discord"
      passHref
      target="_blank"
      w="fit-content"
    >
      <Stack spacing={0} gap={1} width="fit-content" alignItems="center">
        <Discord w={{ base: '26px', xl: '32px' }} h={{ base: '20px', xl: '26px' }} />
        <Text
          as="span"
          fontSize={{ base: 'xx-small', xl: 'xs' }}
          fontFamily="heading"
          fontWeight="400"
          color="txtDarkGray"
          textTransform="capitalize"
        >
          {t('discord-button')}
        </Text>
      </Stack>
    </Button>
  )
}

export default MetaverseButton
