import { IconButton, useDisclosure } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import { Hamburger } from '../../icons/hamburger'
import { PageMenuModal } from '../../modals/page-menu-modal'

interface PageMenuButtonProps {
  isHomePage: boolean
}

export function PageMenuButton({ isHomePage }: PageMenuButtonProps) {
  const { t } = useTranslation('footer')
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <IconButton
        aria-label={t('aria-label-play-music-button')}
        height={14}
        width={14}
        fontSize="2em"
        _hover={{ backgroundColor: 'transparent', color: 'txtDarkGray' }}
        bg="transparent"
        variant="edgy"
        icon={<Hamburger />}
        onClick={onOpen}
      />
      <PageMenuModal isOpen={isOpen} onClose={onClose} isHomePage={isHomePage} />
    </>
  )
}
