import { IconButton } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import { useEffect, useState } from 'react'
import { Pause } from '../../icons/pause'
import { Play } from '../../icons/play'

export function PlayMusicButton() {
  const { t } = useTranslation('footer')
  const [isPlaying, setIsPlaying] = useState(false)
  const [audioSrc, setAudioSrc] = useState<string | null>(null)
  const [audioPlayer, setAudioPlayer] = useState<HTMLAudioElement | null>(null)

  useEffect(() => {
    if (isPlaying) {
      audioPlayer?.play()
    } else {
      audioPlayer?.pause()
    }
  }, [isPlaying])

  return (
    <>
      <IconButton
        aria-label={t('aria-label-play-music-button')}
        height={14}
        width={14}
        fontSize="2em"
        variant="edgy"
        icon={isPlaying ? <Pause /> : <Play w={19} h={19} />}
        onMouseEnter={() => {
          if (audioSrc) {
            setAudioSrc('/sounds/portrait_xo_home.mp3')
          }
          setAudioPlayer(document.getElementById('music-player-footer') as HTMLAudioElement | null)
        }}
        onClick={() => setIsPlaying(!isPlaying)}
      />
      <audio src={audioSrc ?? ''} id="music-player-footer" />
    </>
  )
}
