import type { IconProps } from '@chakra-ui/icons'
import { createIcon } from '@chakra-ui/icons'
import type { ComponentWithAs } from '@chakra-ui/react'

export const User: ComponentWithAs<'svg', IconProps> = createIcon({
  displayName: 'User',
  viewBox: '0 0 40 40',
  path: (
    <>
      <circle cx="20" cy="20" r="19" stroke="white" strokeWidth={2} />
      <path
        d="M12.4645 22.4645C13.4021 21.5268 14.6739 21 16 21H24C25.3261 21 26.5979 21.5268 27.5355 22.4645C28.4732 23.4021 29 24.6739 29 26V28C29 28.5523 28.5523 29 28 29C27.4477 29 27 28.5523 27 28V26C27 25.2044 26.6839 24.4413 26.1213 23.8787C25.5587 23.3161 24.7956 23 24 23H16C15.2044 23 14.4413 23.3161 13.8787 23.8787C13.3161 24.4413 13 25.2044 13 26V28C13 28.5523 12.5523 29 12 29C11.4477 29 11 28.5523 11 28V26C11 24.6739 11.5268 23.4021 12.4645 22.4645Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        d="M20 11C18.3431 11 17 12.3431 17 14C17 15.6569 18.3431 17 20 17C21.6569 17 23 15.6569 23 14C23 12.3431 21.6569 11 20 11ZM15 14C15 11.2386 17.2386 9 20 9C22.7614 9 25 11.2386 25 14C25 16.7614 22.7614 19 20 19C17.2386 19 15 16.7614 15 14Z"
        fill="white"
        fillRule="evenodd"
      />
    </>
  ),
})
