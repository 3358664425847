import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react'
import { PageMenu } from './page-menu'

interface PageMenuModalProps {
  isOpen: boolean
  onClose: () => void
  isHomePage: boolean
  bgColor?: string
  fontColor?: string
}

export function PageMenuModal({
  isOpen,
  onClose,
  isHomePage,
  bgColor = 'txtBlack',
  fontColor = 'txtWhite',
}: PageMenuModalProps) {
  return (
    <Modal onClose={onClose} size="full" isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent bg={bgColor} h="full" overflow="auto">
        <ModalBody>
          <PageMenu
            onClose={onClose}
            isHomePage={isHomePage}
            fontColor={fontColor}
            bgColor={bgColor}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
