import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react'
import { CloseButtonBar } from '../../close-button-bar'
import { ArtistRegistrar } from './artist-registrar'

interface ArtistModalProps {
  isOpen: boolean
  onClose: () => void
  isHomePage: boolean
}

export function ArtistModal({ isOpen, onClose, isHomePage }: ArtistModalProps) {
  return (
    <Modal onClose={onClose} size="full" isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent bg="txtBlack" h="full" overflow="auto">
        <CloseButtonBar onClose={onClose} variant="light" />
        <ModalBody>
          <ArtistRegistrar onClose={onClose} isHomePage={isHomePage} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
