import {
  AspectRatio,
  Box,
  Button,
  Center,
  Flex,
  Hide,
  HStack,
  IconButton,
  Link,
  Show,
  Text,
  VStack,
} from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import NextLink from 'next/link'
import { BsDiscord, BsInstagram, BsTwitter } from 'react-icons/bs'
import { FaLinkedinIn } from 'react-icons/fa'
import { ChangeLanguage } from '../../change-language'
import { TxtLogo } from '../../icons/txt-logo'
import { Section } from '../../section'
import { FlowingFooterBar } from './flowing-footer-bar'
import { appendBaseUrlToRoute } from '@twlvxtwlv/utils/src/routing'

interface FooterProps {
  isCampaign?: boolean
  isHomePage?: boolean
}

export function Footer({ isCampaign = false, isHomePage = false }: FooterProps) {
  const { t } = useTranslation(['footer', 'common'])

  return (
    <Flex as="footer" w="full" h="full" direction="column" bg="txtBlack" borderStyle="none">
      <Section>
        <Flex w="full" justify="flex-start" direction={['column', 'row']}>
          <Show above="xl">
            <Flex
              w="50%"
              direction="column"
              align="flex-start"
              gap={12}
              justifyContent="space-between"
            >
              <HStack align="flex-start" gap={4}>
                <AspectRatio w={20} h={20} ratio={1}>
                  {!isCampaign ? (
                    <Image
                      src="/images/twelve-logo-txt-circle-white-spinning.gif"
                      alt={t('twelve-logo-txt-circle-alt')}
                      fill
                      sizes="100vw"
                    />
                  ) : (
                    <Image
                      src="/images/campaign/DV/dv-logo-square.png"
                      alt={t('txt-campaign-square-logo-alt')}
                      fill
                      sizes="100vw"
                    />
                  )}
                </AspectRatio>
                <Center h="full">
                  {!isCampaign ? (
                    <TxtLogo w={280} h={30} color="white" />
                  ) : (
                    <Image
                      src="/images/campaign/DV/dv-logo.png"
                      alt={t('txt-campaign-logo-alt')}
                      width={280}
                      height={30}
                      sizes="100vw"
                    />
                  )}
                </Center>
              </HStack>
              <ChangeLanguage variant="dark" />
            </Flex>
          </Show>
          <Flex
            w={['full', 'full', 'full', 'full', '50%']}
            direction="column"
            align="flex-start"
            gap={12}
          >
            <Text color="white" casing="uppercase" w={['full', 'full', '75%']}>
              {!isCampaign ? t('txt-description') : t('txt-campaign-description')}
            </Text>
            <VStack w={['full', '75%', '75%']} gap={2} align="flex-start">
              {!isCampaign ? (
                <>
                  <Button
                    as={NextLink}
                    variant="txtBlack"
                    href="https://www.twlvxtwlv.com/about-us"
                    passHref
                  >
                    {t('about-us')}
                  </Button>

                  <Button
                    as={NextLink}
                    variant="txtBlack"
                    href="https://twelvextwelve.zendesk.com"
                    passHref
                  >
                    {t('help-faq')}
                  </Button>
                </>
              ) : (
                <Button
                  as={NextLink}
                  target="_blank"
                  variant="txtWhite"
                  href="https://embassyone.de/ "
                  passHref
                >
                  {t('txt-campaign-button')}
                </Button>
              )}
            </VStack>
            <Flex
              w="full"
              justify={['flex-start', 'flex-start', 'space-between']}
              direction={['column', 'column', 'row-reverse']}
              gap={4}
            >
              <HStack gap={2} align="flex-end">
                {!isCampaign ? (
                  <>
                    <Link target="_blank" as={NextLink} href="https://www.twlvxtwlv.com/r/discord">
                      <IconButton
                        aria-label={t('aria-label-discord-invitation')}
                        variant="txtWhite"
                        fontSize="2xl"
                        isRound={true}
                        icon={<BsDiscord />}
                      />
                    </Link>
                    <Link
                      target="_blank"
                      as={NextLink}
                      href="https://www.twlvxtwlv.com/r/instagram"
                    >
                      <IconButton
                        aria-label={t('aria-label-instagram')}
                        variant="txtWhite"
                        fontSize="2xl"
                        icon={<BsInstagram />}
                        isRound={true}
                      />
                    </Link>
                    <Link target="_blank" as={NextLink} href="https://www.twlvxtwlv.com/r/linkedin">
                      <IconButton
                        aria-label={t('aria-label-linked-in')}
                        variant="txtWhite"
                        fontSize="2xl"
                        icon={<FaLinkedinIn />}
                        isRound={true}
                      />
                    </Link>

                    <Link target="_blank" as={NextLink} href="https://www.twlvxtwlv.com/r/twitter">
                      <IconButton
                        aria-label={t('aria-label-twitter')}
                        variant="txtWhite"
                        fontSize="2xl"
                        icon={<BsTwitter />}
                        isRound={true}
                      />
                    </Link>
                  </>
                ) : null}
              </HStack>
              <VStack align="flex-start" gap={1} color="white">
                {!isCampaign ? (
                  <>
                    <Link
                      variant="txtWhite"
                      textTransform="uppercase"
                      fontSize={'xs'}
                      as={NextLink}
                      href={appendBaseUrlToRoute('imprint', isHomePage)}
                      target="_blank"
                    >
                      {t('common:navigation-imprint')}
                    </Link>
                    <Link
                      variant="txtWhite"
                      textTransform="uppercase"
                      fontSize={'xs'}
                      as={NextLink}
                      href={appendBaseUrlToRoute('privacy-policy', isHomePage)}
                      target="_blank"
                    >
                      {t('common:navigation-privacy-policy')}
                    </Link>

                    <Link
                      variant="txtWhite"
                      textTransform="uppercase"
                      fontSize={'xs'}
                      as={NextLink}
                      href={appendBaseUrlToRoute('terms-of-service', isHomePage)}
                      target="_blank"
                    >
                      {t('terms-of-service-label')}
                    </Link>
                    <Link
                      variant="txtWhite"
                      textTransform="uppercase"
                      fontSize={'xs'}
                      href={appendBaseUrlToRoute('terms-of-sale', isHomePage)}
                      as={NextLink}
                      target="_blank"
                    >
                      {t('terms-of-sale-label')}
                    </Link>
                  </>
                ) : null}
                <Text color="txtWhite" casing="uppercase" fontSize={'xs'}>
                  Twelve x Twelve © 2023
                </Text>
              </VStack>
            </Flex>
            <Hide above="xl">
              <ChangeLanguage variant="dark" />
            </Hide>
          </Flex>
        </Flex>
      </Section>

      <Box height={14}>
        <FlowingFooterBar isHomePage={isHomePage} />
      </Box>
    </Flex>
  )
}
