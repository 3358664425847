import { Stack } from '@chakra-ui/react'
import type { ReactNode } from 'react'

export function Main({ children }: { children: ReactNode }) {
  return (
    <Stack as="main" flex={1} h="full" spacing={0} gap={0}>
      {children}
    </Stack>
  )
}
