import type { IconProps } from '@chakra-ui/icons'
import { createIcon } from '@chakra-ui/icons'
import type { ComponentWithAs } from '@chakra-ui/react'

export const Pause: ComponentWithAs<'svg', IconProps> = createIcon({
  displayName: 'Pause',
  viewBox: '0 0 11 20',
  path: (
    <path d="M.816 2.568h2.256v14.805H.816zM6.578 2.568h2.256v14.805H6.578z" fill="currentColor" />
  ),
})
