import type { IconProps } from '@chakra-ui/icons'
import { createIcon } from '@chakra-ui/icons'
import type { ComponentWithAs } from '@chakra-ui/react'

export const Discord: ComponentWithAs<'svg', IconProps> = createIcon({
  displayName: 'Discord',
  viewBox: '0 0 31 18',
  path: (
    <path
      d="M23.4705 1.4921C22.0045 0.80148 20.4325 0.292651 18.7889 0.00122172C18.759 -0.0044007 18.7291 0.00965536 18.7136 0.0377675C18.5115 0.406973 18.2875 0.888628 18.1307 1.2672C16.3629 0.995454 14.6041 0.995454 12.8726 1.2672C12.7157 0.880194 12.4836 0.406973 12.2806 0.0377675C12.2651 0.0105924 12.2352 -0.00346363 12.2053 0.00122172C10.5626 0.291714 8.99058 0.800543 7.52373 1.4921C7.51104 1.49772 7.50016 1.50709 7.49291 1.51928C4.51117 6.09312 3.69434 10.5545 4.09505 14.9606C4.09686 14.9822 4.10865 15.0028 4.12496 15.0159C6.09224 16.4993 7.99787 17.3998 9.86815 17.9967C9.89806 18.0061 9.92979 17.9949 9.94883 17.9695C10.3912 17.3492 10.7856 16.6951 11.1238 16.0073C11.1437 15.967 11.1247 15.9192 11.0839 15.9033C10.4583 15.6597 9.86271 15.3626 9.28975 15.0253C9.24442 14.9981 9.24079 14.9316 9.2825 14.8997C9.40307 14.8069 9.52365 14.7104 9.63878 14.613C9.65963 14.5952 9.68864 14.5914 9.71312 14.6027C13.4772 16.3672 17.5523 16.3672 21.272 14.6027C21.2965 14.5905 21.3255 14.5942 21.3473 14.612C21.4624 14.7095 21.583 14.8069 21.7044 14.8997C21.7462 14.9316 21.7434 14.9981 21.6981 15.0253C21.1251 15.3692 20.5295 15.6597 19.9031 15.9024C19.8623 15.9183 19.8441 15.967 19.8641 16.0073C20.2095 16.6942 20.6039 17.3483 21.0381 17.9686C21.0562 17.9949 21.0889 18.0061 21.1188 17.9967C22.9981 17.3998 24.9038 16.4993 26.871 15.0159C26.8883 15.0028 26.8991 14.9831 26.901 14.9616C27.3805 9.86764 26.0977 5.44279 23.5004 1.52021C23.494 1.50709 23.4832 1.49772 23.4705 1.4921ZM11.6858 12.2778C10.5526 12.2778 9.61884 11.2095 9.61884 9.89762C9.61884 8.58572 10.5345 7.51746 11.6858 7.51746C12.8463 7.51746 13.771 8.5951 13.7528 9.89762C13.7528 11.2095 12.8372 12.2778 11.6858 12.2778ZM19.3283 12.2778C18.1951 12.2778 17.2613 11.2095 17.2613 9.89762C17.2613 8.58572 18.1769 7.51746 19.3283 7.51746C20.4887 7.51746 21.4134 8.5951 21.3953 9.89762C21.3953 11.2095 20.4887 12.2778 19.3283 12.2778Z"
      fill="currentColor"
    />
  ),
})
