import type { IconProps } from '@chakra-ui/icons'
import { createIcon } from '@chakra-ui/icons'
import type { ComponentWithAs } from '@chakra-ui/react'

export const ArtistIcon: ComponentWithAs<'svg', IconProps> = createIcon({
  displayName: 'Artist',
  viewBox: '0 0 51 49',
  path: (
    <>
      <path
        d="M23.2709 7.64L23.8209 9H25.5109L22.5209 2H20.8909L17.8509 9H19.4809L20.0309 7.64H23.2709ZM22.7609 6.37H20.5509L21.6609 3.65L22.7609 6.37ZM41.3993 9H43.1893L41.6993 6.69C42.5493 6.29 43.0093 5.51 43.0093 4.43C43.0093 2.88 41.9693 2 40.1393 2H37.0993V9H38.6793V7H40.1393H40.2893L41.3993 9ZM38.6793 5.68V3.31H40.1393C41.0093 3.31 41.5093 3.7 41.5093 4.48C41.5093 5.28 41.0093 5.68 40.1393 5.68H38.6793Z"
        fill="white"
      />
      <rect x="1.11816" y="5.58838" width="7.82353" height="1.11765" fill="white" />
      <path
        d="M7.04816 22.34V21H1.19816V22.34H3.32816V28H4.90816V22.34H7.04816ZM24.3225 28V21H22.7425V28H24.3225ZM41.5978 20.93C39.9778 20.93 38.8878 21.73 38.8878 23C38.8878 25.48 42.6178 24.71 42.6178 26.03C42.6178 26.46 42.2278 26.7 41.5778 26.7C40.8378 26.7 39.8178 26.29 39.0978 25.65L38.4678 26.94C39.2578 27.61 40.4078 28.05 41.5578 28.05C43.1078 28.05 44.2978 27.31 44.2978 25.94C44.3078 23.42 40.5778 24.14 40.5778 22.85C40.5778 22.47 40.9378 22.28 41.4678 22.28C42.0278 22.28 42.9078 22.56 43.6478 23L44.2578 21.69C43.5578 21.23 42.5778 20.93 41.5978 20.93Z"
        fill="white"
      />
      <path
        d="M7.04816 41.34V40H1.19816V41.34H3.32816V47H4.90816V41.34H7.04816ZM24.1025 39.93C22.4825 39.93 21.3925 40.73 21.3925 42C21.3925 44.48 25.1225 43.71 25.1225 45.03C25.1225 45.46 24.7325 45.7 24.0825 45.7C23.3425 45.7 22.3225 45.29 21.6025 44.65L20.9725 45.94C21.7625 46.61 22.9125 47.05 24.0625 47.05C25.6125 47.05 26.8025 46.31 26.8025 44.94C26.8125 42.42 23.0825 43.14 23.0825 41.85C23.0825 41.47 23.4425 41.28 23.9725 41.28C24.5325 41.28 25.4125 41.56 26.1525 42L26.7625 40.69C26.0625 40.23 25.0825 39.93 24.1025 39.93Z"
        fill="white"
      />
      <rect
        x="4.4707"
        y="10.0591"
        width="7.82353"
        height="1.11765"
        transform="rotate(-90 4.4707 10.0591)"
        fill="white"
      />
      <rect x="37" y="42.353" width="7.82353" height="1.11765" fill="white" />
      <rect
        x="40.3525"
        y="46.8237"
        width="7.82353"
        height="1.11765"
        transform="rotate(-90 40.3525 46.8237)"
        fill="white"
      />
    </>
  ),
})
