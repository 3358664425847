import { Stack } from '@chakra-ui/react'
import type { ReactNode } from 'react'

interface SectionProps {
  maxWidth?: 'xl' | 'full'
  children: ReactNode
}

// Used in <Main> and <Footer> components
export function Section({ maxWidth = 'xl', children }: SectionProps) {
  return (
    <Stack
      w="full"
      spacing={0}
      gap={4}
      p={maxWidth === 'xl' ? [16, 16, 20, 24] : undefined}
      px={maxWidth === 'xl' ? [2, 4, 8, 12] : undefined}
    >
      {children}
    </Stack>
  )
}
