import { Stack } from '@chakra-ui/react'
import type { AuthenticationStatusType } from '@twlvxtwlv/types'
import type { ReactNode } from 'react'
import { Footer } from './footer'
import { Header } from './header'
import { Main } from './main'
import { Navigation } from './navigation'

type PageProps = {
  authenticationStatus?: AuthenticationStatusType
  bgColor?: string | undefined
  bgImage?: string | null | undefined
  bgRepeat?: boolean
  isCampaign?: boolean
  isHomePage?: boolean
  children: ReactNode
}

export function Page({
  authenticationStatus,
  bgColor = 'white',
  bgImage,
  bgRepeat = false,
  isCampaign = false,
  isHomePage = false,
  children,
}: PageProps) {
  return (
    <Stack
      w="full"
      minH="100vh"
      bg={bgColor ?? undefined}
      bgImage={
        bgColor !== 'none'
          ? undefined
          : bgImage ?? '/images/twelvextwelve.com-background-image.webp'
      }
      bgRepeat={bgRepeat ? 'repeat' : 'no-repeat'}
      bgSize={bgRepeat ? 'auto' : 'cover'}
      spacing={0}
    >
      <Header>
        <Navigation
          authenticationStatus={authenticationStatus}
          isCampaign={isCampaign}
          isHomePage={isHomePage}
        />
      </Header>
      <Main>{children}</Main>
      <Footer isCampaign={isCampaign} isHomePage={isHomePage} />
    </Stack>
  )
}
