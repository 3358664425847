import { Flex } from '@chakra-ui/react'
import type { ReactNode } from 'react'
import React from 'react'

interface HeaderProps {
  children: ReactNode
}

export function Header({ children }: HeaderProps) {
  return (
    <Flex w="full" bg="txtBlack" direction="row" justify="space-between" top={0} p={0} m={0}>
      {children}
    </Flex>
  )
}
