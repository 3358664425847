import type { IconProps } from '@chakra-ui/icons'
import { createIcon } from '@chakra-ui/icons'
import type { ComponentWithAs } from '@chakra-ui/react'

export const Play: ComponentWithAs<'svg', IconProps> = createIcon({
  displayName: 'Play',
  viewBox: '0 0 20 21',
  path: (
    <path
      d="M18.9186 11.3866c.7162-.374.7162-1.399 0-1.773L1.4628.5005C.797.1529 0 .6359 0 1.3869v18.2264c0 .751.797 1.234 1.4628.8864l17.4558-9.1131Z"
      fill="currentColor"
    />
  ),
})
