import { Box, Flex, IconButton, useDisclosure } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import { ArtistIcon } from '../../icons/artists'
import { ArtistModal } from '../../modals/artist-modal'
import MetaverseButton from './metaverse-button'
import { PageMenuButton } from './page-menu-button'
import { PlayMusicButton } from './play-music-button'
import { ShopButton } from './shop-button'

interface FlowingFooterBarProps {
  isHomePage: boolean
}

export function FlowingFooterBar({ isHomePage }: FlowingFooterBarProps) {
  const { t } = useTranslation(['navigation', 'nft-details'])
  const { isOpen, onToggle, onClose } = useDisclosure()

  return (
    <>
      <Flex
        direction="row"
        justify="space-between"
        width="full"
        position="fixed"
        bottom={0}
        zIndex="sticky"
        bg="txtBlack"
        border="none"
        borderTop="1px solid"
        borderTopColor="txtDarkGray"
      >
        <Box border="none" borderRight="1px solid" borderRightColor="txtDarkGray">
          <PlayMusicButton />
        </Box>
        <MetaverseButton />
        <PageMenuButton isHomePage={isHomePage} />
        <ShopButton isHomePage={isHomePage} />
        <Flex
          border="none"
          borderLeft="1px solid"
          borderLeftColor="txtDarkGray"
          justify="center"
          align="center"
        >
          <IconButton
            aria-label={t('aria-label-artists-button')}
            height={14}
            width={14}
            icon={<ArtistIcon w="50px" h="49px" />}
            onClick={onToggle}
            variant="edgy"
          />
        </Flex>
      </Flex>
      <ArtistModal isOpen={isOpen} onClose={onClose} isHomePage={isHomePage} />
    </>
  )
}
