import { Link, Stack, Text } from '@chakra-ui/react'
import type { InfiniteData } from '@tanstack/react-query'
import { useInfiniteQuery } from '@tanstack/react-query'
import type { GetArtistsByPageQuery } from '@twlvxtwlv/tokenforge'
import { type Customer, getArtistsByPage, sortCustomersByProfileName } from '@twlvxtwlv/tokenforge'
import { useTranslation } from 'next-i18next'
import NextLink from 'next/link'
import { useMemo } from 'react'
import { neueMachina } from '../../../../../fonts'
import { appendBaseUrlToRoute } from '@twlvxtwlv/utils/src/routing'

interface ArtistRegistrarProps {
  onClose: () => void
  isHomePage: boolean
}

export function ArtistRegistrar({ onClose, isHomePage }: ArtistRegistrarProps) {
  const { t } = useTranslation(['common'])

  const { data: artistsResponse } = useInfiniteQuery({
    queryKey: ['artists'],
    queryFn: () =>
      getArtistsByPage({
        page: 1,
        itemsPerPage: 100,
      }),
    getNextPageParam: (lastPage, allPages) => {
      const lastPageInCollection = lastPage?.customers?.paginationInfo.lastPage ?? 1
      return lastPageInCollection > allPages.length ? allPages.length + 1 : undefined
    },
  })

  function mapArtistsByPage(artistsResponse: InfiniteData<GetArtistsByPageQuery> | undefined) {
    if (!artistsResponse) return []
    const allPages = artistsResponse.pages
    const allArtistsCollections = allPages?.map((page) => page.customers?.collection)
    const allArtists = allArtistsCollections.flatMap((p) => p)

    return sortCustomersByProfileName(allArtists as Customer[], 'ASC')
  }

  const mappedAndSortedArtists = useMemo(() => mapArtistsByPage(artistsResponse), [artistsResponse])

  return (
    <Stack color="white" align="center" spacing={0} gap={2}>
      <Stack
        color="white"
        align="flex-start"
        spacing={0}
        gap={2}
        fontSize={['4xl']}
        fontWeight="400"
        fontFamily={neueMachina.style.fontFamily}
      >
        <Text fontSize="xs" color="txtDarkGray" textTransform="uppercase">
          {t('navigation-all-artists')}
        </Text>
        {mappedAndSortedArtists?.map((artist) => {
          return (
            <Link
              as={NextLink}
              prefetch={false}
              key={artist.uuidAsString}
              href={appendBaseUrlToRoute('gallery?artist=' + artist.uuidAsString, isHomePage)}
              onClick={onClose}
              textDecoration="none"
              transition="padding 175ms linear"
              pr="1rem"
              _hover={{
                pl: '1rem',
                pr: '0',
                transition: 'padding 100ms linear',
                color: 'txtDarkGray',
              }}
            >
              {artist.profileName}
            </Link>
          )
        })}
      </Stack>
    </Stack>
  )
}
