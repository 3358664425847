import { Button, Stack, Text } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import NextLink from 'next/link'
import { Shop } from '../../icons/shop'
import { appendBaseUrlToRoute } from '@twlvxtwlv/utils/src/routing'

export interface ShopButtonProps {
  isHomePage: boolean
}

export function ShopButton({ isHomePage }: ShopButtonProps) {
  const { t } = useTranslation('footer')

  return (
    <Button
      as={NextLink}
      bg="transparent"
      variant="edgy"
      _hover={{ backgroundColor: 'transparent', color: 'txtDarkGray' }}
      alignSelf="center"
      href={appendBaseUrlToRoute('gallery', isHomePage)}
      passHref
      w="fit-content"
    >
      <Stack spacing={0} gap={1} width="fit-content" alignItems="center">
        <Shop w={{ base: '20px', xl: '26px' }} h={{ base: '20px', xl: '26px' }} />
        <Text
          as="span"
          fontSize={{ base: 'xx-small', xl: 'xs' }}
          fontFamily="heading"
          fontWeight="400"
          color="txtDarkGray"
          textTransform="capitalize"
        >
          {t('marketplace-button')}
        </Text>
      </Stack>
    </Button>
  )
}
