import { IconButton, useBreakpoint } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import NextLink from 'next/link'
import { User } from '../../../icons/user'
import { appendBaseUrlToRoute } from '@twlvxtwlv/utils/src/routing'

export interface UserProfileButtonProps {
  isHomePage: boolean
}

export function UserProfileButton({ isHomePage }: UserProfileButtonProps) {
  const { t } = useTranslation('navigation')

  const breakpoint = useBreakpoint()
  const isDesktop = breakpoint === 'xl'

  const route = isDesktop ? 'user/my-account' : 'user'

  return (
    <IconButton
      as={NextLink}
      aria-label={t('aria-label-user-profile-button')}
      height={14}
      width={14}
      fontSize="2em"
      bg="txtBlack"
      _hover={{ backgroundColor: 'black' }}
      variant="edgy"
      icon={<User w="40px" h="40px" />}
      href={appendBaseUrlToRoute(route, isHomePage)}
      passHref
    />
  )
}
