import { Box, Button, Flex, Grid, GridItem, Hide, Icon, Link, Stack, Text } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import NextLink from 'next/link'
import type { ReactNode } from 'react'
import { FiDownloadCloud } from 'react-icons/fi'
import { ChangeLanguage } from '../../../change-language'
import { CloseButtonBar } from '../../../close-button-bar'
import { appendBaseUrlToRoute } from '@twlvxtwlv/utils/src/routing'
import { useRouter } from 'next/router'

interface MenuItem {
  href: string
  translationKey: string
  target?: string
  icon?: ReactNode
  isInternal?: boolean
}

interface SubMenuItem {
  gridArea: string
  titleTranslationKey: string
  navigationItems: MenuItem[]
}

const mainMenuItems: MenuItem[] = [
  { href: 'https://www.twlvxtwlv.com', translationKey: 'navigation-home' },
  { href: 'https://twlvxtwlv.com/advance', translationKey: 'navigation-advance' },
  { href: 'https://twlvxtwlv.com/play', translationKey: 'navigation-play' },
  { href: 'https://twlvxtwlv.com/en/editorials', translationKey: 'navigation-editorial' },
]

const productMenuItems: MenuItem[] = [
  {
    href: 'https://twlvxtwlv.com/advance/',
    translationKey: 'navigation-song-stocks',
  },
  {
    href: 'https://twlvxtwlv.com/play/',
    translationKey: 'navigation-digital-vinyl',
  },
  {
    href: 'https://twlvxtwlv.com/play/',
    translationKey: 'navigation-prelistening',
  },
  {
    href: 'https://twlvxtwlv.com/play/',
    translationKey: 'navigation-digital-collectible',
  },
]

const companyMenuItems: MenuItem[] = [
  {
    href: 'https://www.twlvxtwlv.com/about-us/',
    translationKey: 'navigation-about-us',
  },
  {
    href: 'mailto:contact@twlvxtwlv.com?subject=Contact via Website',
    translationKey: 'navigation-contact',
  },
  {
    href: 'https://360x-music-ag.jobs.personio.com/',
    translationKey: 'navigation-career',
  },
  {
    href: 'https://drive.google.com/file/d/19yLf3SBE2sgTSYc1Q3YnsL1i3ciH4PYz/view',
    translationKey: 'navigation-press-kit',
    icon: <Icon as={FiDownloadCloud} />,
  },
]

const supportMenuItems: MenuItem[] = [
  {
    href: 'https://twelvextwelve.zendesk.com',
    translationKey: 'navigation-faq',
  },
  {
    href: 'https://twelvextwelve.zendesk.com/hc/en-150/requests/new',
    translationKey: 'navigation-help',
  },
  {
    href: 'https://twelvextwelve.zendesk.com/hc/en-150/sections/4404330367889-Getting-Started',
    translationKey: 'navigation-getting-started',
  },
  {
    href: 'terms-of-service',
    translationKey: 'navigation-terms-of-usage',
    target: '_blank',
    isInternal: true,
  },
]

const followUsMenuItems: MenuItem[] = [
  {
    href: 'https://www.twlvxtwlv.com/r/discord/',
    translationKey: 'navigation-discord',
    target: '_blank',
  },
  {
    href: 'https://www.twlvxtwlv.com/r/instagram/',
    translationKey: 'navigation-instagram',
    target: '_blank',
  },
  {
    href: 'https://www.twlvxtwlv.com/r/linkedin/',
    translationKey: 'navigation-linkedin',
    target: '_blank',
  },
  {
    href: 'https://www.twlvxtwlv.com/r/twitter/',
    translationKey: 'navigation-twitter',
    target: '_blank',
  },
]

const subMenu: SubMenuItem[] = [
  {
    gridArea: 'products',
    titleTranslationKey: 'navigation-products',
    navigationItems: productMenuItems,
  },
  {
    gridArea: 'company',
    titleTranslationKey: 'navigation-company',
    navigationItems: companyMenuItems,
  },
  {
    gridArea: 'support',
    titleTranslationKey: 'navigation-support',
    navigationItems: supportMenuItems,
  },
  {
    gridArea: 'follow-us',
    titleTranslationKey: 'navigation-follow-us',
    navigationItems: followUsMenuItems,
  },
]

interface PageMenuProps {
  onClose: () => void
  isHomePage: boolean
  fontColor: string
  bgColor: string
}

export function PageMenu({ onClose, isHomePage, fontColor, bgColor }: PageMenuProps) {
  const router = useRouter()
  const currentPath = router.asPath

  const normalizePath = (href: string, isInternal: boolean): string => {
    if (isInternal) {
      const url = new URL(href, window.location.origin)
      return url.pathname
    } else {
      // For external URLs, extract the path and normalize it, if you want to match against paths
      const url = new URL(href)
      return url.pathname
    }
  }

  const { t } = useTranslation(['common', 'navigation'])
  return (
    <Stack justify="space-between" h="full" color={fontColor} pb={4}>
      <Stack w="full" gap={8} mt={8}>
        <Hide above="md">
          <Flex align="center" w="full" h="8">
            <CloseButtonBar
              onClose={onClose}
              variant={fontColor === 'txtBlack' ? 'dark' : 'light'}
            />
          </Flex>
        </Hide>
        <Hide below="md">
          <Flex align="center" w="full" gap={4} h="12">
            <Flex align="center" h="full" flex="1" justifyContent="space-evenly" gap={2}>
              <Button
                as={NextLink}
                variant={bgColor}
                size="sm"
                fontSize="11px"
                href="https://www.twlvxtwlv.com/r/discord"
                target="_blank"
                passHref
              >
                {t('navigation:join-discord')}
              </Button>
              <Button
                as={NextLink}
                variant={bgColor}
                size="sm"
                fontSize="11px"
                href="https://9s9gk0rzd7e.typeform.com/to/ND6NgPti"
                target="_blank"
                passHref
              >
                {t('common:navigation-create-nft')}
              </Button>
            </Flex>
            <Flex align="center" justifyContent="center" h="full" flex="1">
              <CloseButtonBar
                onClose={onClose}
                variant={fontColor === 'txtBlack' ? 'dark' : 'light'}
              />
            </Flex>
            <Flex align="center" h="full" flex="1">
              {/*TODO: Insert search bar here once we have the search component*/}
            </Flex>
          </Flex>
        </Hide>
      </Stack>

      <Grid
        gridTemplateColumns={[
          'repeat(2, 1fr)',
          'repeat(2, 1fr)',
          'repeat(4, 1fr)',
          'repeat(4, 1fr)',
          'repeat(7, 1fr)',
        ]}
        templateAreas={[
          `'menu menu' 'products company' 'support follow-us' 'language ..' 'footer footer'`,
          `'menu menu' 'products company' 'support follow-us' 'language ..' 'footer footer'`,
          `'menu menu menu menu' 'products company support follow-us' 'language footer footer footer'`,
          `'menu menu menu menu' 'products company support follow-us' 'language footer footer footer'`,
          `'menu menu menu products company support follow-us' 'language language language language footer footer footer'`,
        ]}
        rowGap={16}
        columnGap={12}
      >
        <GridItem gridArea="menu">
          <Stack color={fontColor} align="flex-start" spacing={0} gap={4}>
            <Text
              color={fontColor === 'txtBlack' ? fontColor : 'txtDarkGrey'}
              fontSize="xs"
              textTransform="uppercase"
            >
              {t('navigation-menu')}
            </Text>
            <Stack
              fontSize={[30, 36, 64, 64, 70]}
              fontWeight="600"
              align="flex-start"
              spacing={0}
              gap={[3, 3, 4]}
            >
              {mainMenuItems.map(({ href, translationKey, target, isInternal }, index) => {
                const normalizedHref = normalizePath(href, isInternal || false)
                const isActive = normalizedHref === currentPath
                return (
                  <Link
                    as={NextLink}
                    key={index}
                    href={isInternal ? appendBaseUrlToRoute(href, isHomePage) : href}
                    target={target ?? '_self'}
                    textDecoration="none"
                    transition="padding 175ms linear"
                    pr="1rem"
                    _hover={{
                      pl: '1rem',
                      pr: '0',
                      transition: 'padding 100ms linear',
                      color: fontColor === 'txtBlack' ? 'txtBlack' : 'txtDarkGray',
                    }}
                  >
                    <Box display="flex" gap={2} flexDirection="row" position="relative">
                      {t(translationKey)}
                      {isActive && (
                        <Box
                          position="absolute"
                          top="50%"
                          transform="rotate(180deg) scaleY(-1) translateY(-50%)"
                          right={{ base: '-2rem', md: '-3.5rem' }}
                          fontSize={{ base: '2xl', md: '4xl' }}
                          as="span"
                          ml={2}
                        >
                          &#x21B3;
                        </Box>
                      )}
                    </Box>
                  </Link>
                )
              })}
            </Stack>
          </Stack>
        </GridItem>

        {subMenu.map(({ gridArea, titleTranslationKey, navigationItems }, index) => {
          return (
            <GridItem
              key={index}
              gridArea={gridArea}
              alignSelf={['flex-start', 'flex-start', 'flex-start', 'flex-start', 'flex-end']}
            >
              <Stack
                w="full"
                color={fontColor}
                align="flex-start"
                spacing={0}
                gap={4}
                fontSize={['s', 's', 'md']}
                fontWeight="400"
              >
                <Text
                  fontSize="xs"
                  color={fontColor === 'txtBlack' ? fontColor : 'txtDarkGrey'}
                  textTransform="uppercase"
                >
                  {t(titleTranslationKey)}
                </Text>
                <Stack align="flex-start" spacing={0} gap={[3, 3, 4]}>
                  {navigationItems.map(
                    ({ href, translationKey, icon, target, isInternal }, index) => {
                      return (
                        <Link
                          variant={href === '' ? 'comingSoonDarker' : fontColor}
                          cursor={href === '' ? 'default' : 'pointer'}
                          target={target ?? '_self'}
                          as={NextLink}
                          key={index}
                          href={isInternal ? appendBaseUrlToRoute(href, isHomePage) : href}
                          onClick={href === '' ? (e) => e.preventDefault() : undefined}
                        >
                          {t(translationKey)}
                          {icon ? (
                            <Box as="span" ml={2}>
                              {icon}
                            </Box>
                          ) : null}
                        </Link>
                      )
                    }
                  )}
                </Stack>
              </Stack>
            </GridItem>
          )
        })}

        <GridItem
          gridArea="language"
          alignSelf={['flex-start', 'flex-start', 'flex-start', 'flex-start', 'flex-end']}
        >
          <Stack w="full" color={fontColor} align="flex-start" spacing={0} gap={4} fontWeight="400">
            <Stack w="full" align="flex-start" spacing={0} gap={8}>
              <ChangeLanguage variant={fontColor === 'txtBlack' ? 'light' : 'dark'} />
              <Hide above="md">
                <Stack w="full" spacing={0} gap={[3, 3, 4]}>
                  <Button
                    as={NextLink}
                    variant={bgColor}
                    size="sm"
                    fontSize="11px"
                    href="https://www.twlvxtwlv.com/r/discord"
                    target="_blank"
                    passHref
                  >
                    {t('navigation:join-discord')}
                  </Button>
                  <Button
                    as={NextLink}
                    variant={bgColor}
                    size="sm"
                    fontSize="11px"
                    href="https://9s9gk0rzd7e.typeform.com/to/ND6NgPti"
                    target="_blank"
                    passHref
                  >
                    {t('common:navigation-create-nft')}
                  </Button>
                </Stack>
              </Hide>
            </Stack>
          </Stack>
        </GridItem>

        <GridItem gridArea="footer">
          <Flex
            w="full"
            h="full"
            align="end"
            justify="space-between"
            color={fontColor === 'txtWhite' ? 'txtLightGray' : 'txtBlack'}
            fontSize="xs"
          >
            <Link
              // variant="txtWhite"
              textTransform="uppercase"
              fontSize="xs"
              as={NextLink}
              href={appendBaseUrlToRoute('imprint', isHomePage)}
              target="_blank"
            >
              {t('common:navigation-imprint')}
            </Link>
            <Link
              // variant="txtWhite"
              textTransform="uppercase"
              fontSize="xs"
              as={NextLink}
              href={appendBaseUrlToRoute('privacy-policy', isHomePage)}
              target="_blank"
            >
              {t('common:navigation-privacy-policy')}
            </Link>
            <Text>TXT &#169; {new Date().getFullYear()}</Text>
          </Flex>
        </GridItem>
      </Grid>
    </Stack>
  )
}
