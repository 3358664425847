import type { IconProps } from '@chakra-ui/icons'
import { createIcon } from '@chakra-ui/icons'
import type { ComponentWithAs } from '@chakra-ui/react'

export const Shop: ComponentWithAs<'svg', IconProps> = createIcon({
  displayName: 'Shop',
  viewBox: '0 0 25 26',
  path: (
    <>
      <rect
        x="1"
        y="1"
        width="23"
        height="24"
        rx="3"
        stroke="currentColor"
        strokeWidth="2"
        fill="transparent"
      />
      <path
        d="M8.01144 6C7.89223 7.33333 8.65515 10 12.6605 10C16.6658 10 17.1901 7.33333 16.9516 6"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        fill="transparent"
      />
    </>
  ),
})
